import React from 'react'
import * as S from './styles'

const HeroNewsroom = () => {
  return (
    <S.ContentWrapper className='content_wrapper'>
      <div className='container row'>
        <div className='col-12 col-md-8 col-lg-6 content_wrappe__texts'>
          <h1 className='content_wrapper__texts__title'>
            Newsroom
          </h1>
          <p className='content_wrapper__texts__description'>
            Quer saber mais sobre o Inter? Inscreva-se e fique por dentro das nossas novidades.
          </p>
        </div>
      </div>
    </S.ContentWrapper>
  )
}

export default HeroNewsroom
