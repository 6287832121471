import React from 'react'

import Layout from 'src/layouts/BaseLayout'
import pageContext from './pageContext.json'

import HeroNewsroom from './sections/HeroNewsroom/_index'
import FiquePorDentro from './sections/FiquePorDentro/_index'
import CarouselSec from './sections/CarouselSec/_index'
import SuperAppFinanceiroCompleto from './sections/SuperAppFinanceiroCompleto/_index'

const Newsroom = () => {
  return (
    <Layout pageContext={pageContext}>
      <HeroNewsroom />
      <FiquePorDentro />
      <CarouselSec />
      <SuperAppFinanceiroCompleto />
    </Layout>
  )
}

export default Newsroom
