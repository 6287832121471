import styled from "styled-components"
import * as newColors from 'src/styles/newColors'
import { device } from "src/styles/breakpoints"

export const ContentWrapper = styled.section`
  background-color: ${newColors.sand};
  padding: 0 0 24px 0;
  overflow: hidden;

  @media ${device.tablet}{
    padding: 34px 24px 64px 24px;
  }

  @media ${device.desktopXL}{
    padding: 66px 156px 96px 156px;
  }

  .content_wrapper{
    &__carousel{
      padding: 0 24px;
      overflow: visible;
    }
  }
`
