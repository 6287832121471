import { OrangeCardProps } from "./components/OrangeCard/_index"

export const cardsInfos: OrangeCardProps[] = [
  {
    title: 'Imprensa',
    description: 'Envie uma mensagem para a nossa assessoria de imprensa:',
    link: 'imprensa@bancointer.com.br <br> inter@loures.com.br',
    icon: {
      icon: 'ic_message',
      lib: 'interco',
      className: 'className',
      size: 'MD',
      color: 'white',
      customPath: 'media-communication/',
    },
  },
  {
    title: 'Relatorio Anual',
    description: 'Tudo sobre as ações e avanços que fizeram o Inter continuar crescendo em 2023.',
    link: '<a href="https://api.mziq.com/mzfilemanager/v2/d/b4dc0b14-a83a-40a9-9545-d9e4f18ed7af/7f1035ec-6f3f-7b5e-1b8b-d7c553302448?origin=2" class="text-decoration-underline text-white" >Saiba mais</a>',
    icon: {
      icon: 'ic_document_blank',
      lib: 'interco',
      className: 'className',
      size: 'MD',
      color: 'white',
      customPath: 'files/',
    },
  },
  {
    title: 'Investidores',
    description: 'Para resultados, governança corporativa e arquivos regulatórios.',
    link: '<a class="text-decoration-underline text-white" href="https://investors.inter.co/">Saiba Mais</a>',
    icon: {
      icon: 'ic_trending_up',
      lib: 'interco',
      className: 'className',
      size: 'MD',
      color: 'white',
      customPath: 'finance/',
    },
  },
  {
    title: 'Patrocínios',
    description: 'Envie a sua proposta para a nossa equipe',
    link: 'patrocinios@bancointer.com.br',
    icon: {
      icon: 'ic_handshake',
      lib: 'interco',
      className: 'className',
      size: 'MD',
      color: 'white',
      customPath: 'human/',
    },
  },
  {
    title: 'Materiais de marca',
    description: 'Baixe materiais de marca do Inter, caso precise utilizar para divulgações.',
    link: '<a class="text-decoration-underline text-white" href="https://static.bancointer.com.br/press-kit/press-kit.zip">Download</a>',
    icon: {
      icon: 'ic_arrow_download',
      lib: 'interco',
      className: 'className',
      size: 'MD',
      color: 'white',
      customPath: 'action-navigation/',
    },
  },
]
