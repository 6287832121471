import { device } from "src/styles/breakpoints"
import styled from "styled-components"
import { IconProp } from "./types"

export const ListItem = styled.li`
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  margin-top: 32px;
`

export const Icon = styled.div<IconProp>`
  background-color: ${(props: IconProp) => props.backgroundColor};
  margin-bottom: 16px;

  border-radius: 100%;
  width: 50px;
  height: 50px;

  display: flex;
  align-items: center;
  justify-content: center;

  @media ${device.tablet} {
    width: 60px;
    height: 60px;

    margin-bottom: 40px;
  }

  @media ${device.desktopLG} {
    width: 70px;
    height: 70px;
  }

  @media ${device.desktopXXL}{
    margin-bottom: 42px;
  }
`

export const Title = styled.h3`
  font-family: Citrina, sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: white;
  text-align: center;
  margin: 0;

  @media ${device.desktopLG}{
    font-size: 28px;
    line-height: 34px;
  }

  @media ${device.desktopXXL}{
    font-size: 32px;
    line-height: 38px;
  }
`
