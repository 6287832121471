import React, { PropsWithChildren } from "react"
import * as S from './styles'
import * as T from './types'
import { QualityItemContext, useQualityItemContext } from "./context"

const IconAndTitle = (props: T.QualityItemProps & PropsWithChildren & T.SubComponentsProps) => {
  return (
    <QualityItemContext.Provider value={{
        title: props.title,
        iconBackgroundColor: props.iconBackgroundColor,
    }}
    >
      <S.ListItem className={props.className}>
        {props.children}
      </S.ListItem>
    </QualityItemContext.Provider>
  )
}

IconAndTitle.Icon = ({ className, children }: T.SubComponentsProps & PropsWithChildren) => {
  const qualityContext = useQualityItemContext()

  return (
    <S.Icon
      backgroundColor={qualityContext.iconBackgroundColor}
      className={className}
    >
      {children}
    </S.Icon>
  )
}

IconAndTitle.Title = ({ className }: T.SubComponentsProps) => {
  const qualityContext = useQualityItemContext()

  return (
    <S.Title
      className={className}
      dangerouslySetInnerHTML={{ __html: qualityContext.title }}
    />
  )
}

export default IconAndTitle
