import React from 'react'
import * as S from './styles'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import OrangeCard, { OrangeCardProps } from './components/OrangeCard/_index'
import * as newColors from 'src/styles/newColors'

import { cardsInfos } from './_cardsInfos'

const CarouselSec = () => {
  return (
    <S.ContentWrapper>
      <DefaultCarousel
        customDotColor={newColors.primary[500]}
        sm={{ items: 1, partialVisibilityGutter: 50 }}
        md={{ items: 2, partialVisibilityGutter: 20 }}
        lg={{ items: 2, partialVisibilityGutter: 120 }}
        xl={{ items: 2, partialVisibilityGutter: 160 }}
        containerClass='content_wrapper__carousel'
      >
        { cardsInfos.map((card: OrangeCardProps, index: number) => (
          <OrangeCard
            title={card.title}
            description={card.description}
            link={card.link}
            icon={card.icon}
            key={`card${index}`}
          />
        ))}
      </DefaultCarousel>
    </S.ContentWrapper>
  )
}

export default CarouselSec
