import React from 'react'
import * as Types from './types'

export const QualityItemContext = React.createContext<Types.QualityItemProps | undefined>(undefined)

export const useQualityItemContext = () => {
  const context = React.useContext(QualityItemContext)
  if (!context) {
    throw new Error('This component must be wrapped in a QualityItem component')
  }
  return context
}
