import React from 'react'
import * as S from './styles'
import IconsSwitch from 'src/components/IconsSwitch/_index'
import { IconsSwitchProps } from 'src/components/IconsSwitch/types'

export type OrangeCardProps = {
  title: string;
  description: string;
  link: string;
  icon: IconsSwitchProps;
}

const OrangeCard = ({ title, description, link, icon }: OrangeCardProps) => {
  return (
    <S.Card className='card'>
      <div>
        <div className='card__icon'>
          <IconsSwitch
            icon={icon.icon}
            size='MD'
            color='white'
            lib={icon.lib}
            className={`w-100 h-100 ${icon.className ?? ''}`}
            customPath={icon.customPath}
          />
        </div>
        <h3
          className='card__title'
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <p
          className='card__description'
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
      <span
        className='card__links'
        dangerouslySetInnerHTML={{ __html: link }}
      />
    </S.Card>
  )
}

export default OrangeCard
